import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon';

export const CompletedFlight = ({ flight }) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <span className="label">Date:</span> {DateTime.fromSeconds(flight.start).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </Col>
                <Col xs={12}>
                    <span className="label">Aircraft:</span> {flight.aircraft.tailNumber} - {flight.aircraft.type}
                </Col>
                <Col xs={12} lg={3}>
                    <span className="label">Time:</span> {DateTime.fromSeconds(flight.start).toLocaleString(DateTime.TIME_24_SIMPLE)} to {DateTime.fromSeconds(flight.end).toLocaleString(DateTime.TIME_24_SIMPLE)}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <span className="label">Hours:</span> {flight.hours}
                </Col>
                <Col xs={12}>
                    <span className="label">Prepaid Hours Used:</span> {flight.prePaidHours}
                </Col>
                <Col xs={12}>
                    <span className="label">Billed Amount:</span> {flight.invoicedAmount}
                </Col>
            </Row>
        </>
    )
}

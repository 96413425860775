import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { roles } from '../../reducers/auth';
import { GET_ALL_AIRCRAFT } from '../../gql/Aircraft';

import { useQuery } from '@apollo/client';
import { Container, Table } from 'react-bootstrap';

const AircraftList = ({ variant }) => {
    const userRoles = useSelector(roles);
    const navigate = useNavigate();

    const { loading: loadingCraft, data } = useQuery(GET_ALL_AIRCRAFT);

    if (userRoles.includes('admin') === false) return <>Unauthorized</>

    if (loadingCraft) return <p>Loading ...</p>;

    const viewCraft = (event, tailNumber) => {
        event.preventDefault();
        return navigate(`aircraft/${tailNumber}`);
    }

    return (
        <Container>
            <Table name="aircraft" striped bordered variant={variant.toLowerCase()}>
                <thead>
                    <tr>
                        <th>Tail Number</th>
                        <th>Type</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data.allAircraft.map((craft) => (
                        <tr key={craft.tailNumber}>
                            <td>
                                <a href={`aircraft/${craft.tailNumber}`} onClick={(event) => viewCraft(event, craft.tailNumber)} className={variant} style={{ textDecoration: 'none' }}>
                                    {craft.tailNumber}
                                </a>
                            </td>
                            <td>{craft.type}</td>
                            <td>{craft.status}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container >
    )
}

export default AircraftList
import React from 'react'

import { useQuery } from '@apollo/client';
import { Card, Table } from 'react-bootstrap';

import { gql } from '@apollo/client';
import { L8N } from '../../../l8n';

export const GET_AVAILABLE_AIRCRAFT = gql`
    query Aircraft {
        activeAgreements{
            tailNumber
            aircraft {
                type
                status
            }
        }
    }
`;

const AircraftList = ({ variant }) => {
    const { loading: loadingCraft, data } = useQuery(GET_AVAILABLE_AIRCRAFT);

    if (loadingCraft) return <Card style={{ width: '100%' }}
        bg={variant.toLowerCase()}
        text={variant.toLowerCase() === 'lighter' ? 'black' : 'dark'}
        className="mb-2">
        <Card.Body>
            <Card.Title>
                Aircraft
            </Card.Title>
            <Card.Text>
                <Table name="aircraft" striped bordered variant={variant.toLowerCase()}>
                    <thead>
                        <tr>
                            <th>Tail Number</th>
                            <th>Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                        </tr>

                    </tbody>
                </Table>
            </Card.Text>
        </Card.Body>
    </Card>;

    if (!data.activeAgreements || data.activeAgreements.length === 0) {
        return <Card style={{ width: '100%' }}
            bg={variant.toLowerCase()}
            text={variant.toLowerCase() === 'lighter' ? 'black' : 'dark'}
            className="mb-2">
            <Card.Body>
                <Card.Title>
                    Aircraft
                </Card.Title>
                <Card.Text>
                    <Table name="aircraft" striped bordered variant={variant.toLowerCase()}>
                        <thead>
                            <tr>
                                <th>Tail Number</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    }

    return (
        <Card style={{ width: '100%' }}
            bg={variant.toLowerCase()}
            text={variant.toLowerCase() === 'lighter' ? 'black' : 'dark'}
            className="mb-2">
            <Card.Body>
                <Card.Title>
                    Aircraft
                </Card.Title>
                <Card.Text>
                    <Table name="aircraft" striped bordered variant={variant.toLowerCase()}>
                        <thead>
                            <tr>
                                <th>Tail Number</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.activeAgreements.map((craft) => (
                                <tr key={craft.tailNumber}>
                                    <td>
                                        {craft.tailNumber}
                                    </td>
                                    <td>{craft.aircraft.type}</td>
                                    <td>{craft.aircraft.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default AircraftList
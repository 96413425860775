import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client';

import { Container, Row, Col, Navbar, Form, Button } from 'react-bootstrap';
import { validate } from 'isemail';
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';

import { useDispatch } from 'react-redux'
import { setToken, setRoles, setFlags } from '../reducers/auth'
import { L8N } from '../l8n';
import { Turnstile } from '@marsidev/react-turnstile'

const LOGIN = gql`
    mutation Login($input: LoginInput!) {
        login(input: $input) {
            token
            roles
            flags
        }
    }
`;

const CREATE_USER = gql`
    mutation UserCreate($lastName: String!, $firstName: String!, $password: String!, $email: String!) {
        UserCreate(lastName: $lastName, firstName: $firstName, password: $password, email: $email) {
            id
        }
    }
`;

export const Login = () => {
    const dispatch = useDispatch()
    const variant = 'lighter';

    const [state, setState] = useState({
        username: '',
        password: '',
        repassword: '',
        lastName: '',
        firstName: '',
        signingup: false,
        isValid: false,
    })
    const [turnToken, setTurnToken] = useState();

    const [message, setMessage] = useState();

    const [loginUser, { loading }] = useMutation(LOGIN, {
        onError: (error) => {
            if (error.message === 'Failed to fetch') {
                setMessage("Could not connect to server. Please check your internet connection.");
                return;
            }
            setMessage(error.message);
        }
    });
    const [createUser, { loading: createLoading }] = useMutation(CREATE_USER, {
        onError: (error) => {
            setMessage(error.message);
        }
    });

    const updateState = (event) => {
        const newState = {
            ...state,
            [event.target.name]: event.target.value,
        };

        newState.isValid = newState.firstName !== '' && newState.lastName !== '' && validate(newState.username) && zxcvbn(newState.password).score >= 2 &&
            newState.password === newState.repassword;

        setState(newState);
    }

    const handleLogin = (event) => {
        event.preventDefault();
        if (!turnToken) {
            setMessage('Please verify that you are a human.');
            return;
        }

        loginUser({
            variables: {
                "input": {
                    username: state.username,
                    password: state.password,
                }
            },
            onCompleted(data) {
                if (data.login) {
                    dispatch(setToken(data.login.token))
                    dispatch(setRoles(data.login.roles))
                    dispatch(setFlags(data.login.flags))
                }
            }
        });
    }

    const handleNewUser = (event) => {
        event.preventDefault();
        setState({
            ...state,
            signingup: true,
            username: '',
            password: '',
            repassword: '',
            lastName: '',
            firstName: '',
            isValid: false,
        });
        setTurnToken(undefined);
        setMessage('');

    }

    const handleCreation = (event) => {
        event.preventDefault();
        if (!turnToken) {
            setMessage('Please verify that you are a human.');
            return;
        }

        createUser({
            variables: {
                lastName: state.lastName,
                firstName: state.firstName,
                password: state.password,
                email: state.username
            },
            onCompleted(data) {
                setState({
                    ...state,
                    password: '',
                    repassword: '',
                    lastName: '',
                    firstName: '',
                    signingup: false,
                    isValid: false,
                });
                setMessage('Your account was successfully created. Please login to your account and complete the Rental Contract.');
            },
            onError(error) {
                console.error(error);
            }
        });
    }

    const handleCancel = (event) => {
        setState({
            ...state,
            username: '',
            password: '',
            repassword: '',
            lastName: '',
            firstName: '',
            signingup: false,
            isValid: false,
        });
        setMessage('');

        setTurnToken(undefined);
    }
    if (loading || createLoading) return <Container>Loading...</Container>

    if (state.signingup) {
        return <>
            <Navbar
                bg={variant.toLowerCase()}
                variant={variant.toLowerCase()}
                expand="lg">
                <Container>
                    <Navbar.Brand href="/">{L8N.siteTitle}</Navbar.Brand>
                </Container>
            </Navbar>
            <Container style={{ marginTop: "5px" }}>
                <Row>
                    <Col sm="12" md="6" className="d-none d-md-block">
                    </Col>
                    <Col sm="12" md="4">
                        {message && <>
                            <div className={'warningbox-' + variant}>
                                {message}
                            </div>
                        </>}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6" className="d-none d-md-block">
                        <img src="high-resolution-color-logo.png" width="50%" alt={L8N.siteTitle + ' Logo'} />
                    </Col>
                    <Col sm="12" md="4">
                        <Form>
                            <Form.Group controlId="formFirestName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control name="firstName" placeholder="First Name" value={state.name} onChange={updateState} />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control name="lastName" placeholder="Last Name" value={state.lastName} onChange={updateState} />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="username" type="email" placeholder="Enter email" value={state.username} onChange={updateState} />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Password" value={state.password} onChange={updateState} />
                            </Form.Group>
                            <PasswordStrengthBar password={state.password} scoreWords={['weak', 'almost', 'okay', 'good', 'strong']} />
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Retype Password</Form.Label>
                                <Form.Control name="repassword" type="password" placeholder="Password" value={state.repassword} onChange={updateState} />
                            </Form.Group>
                            {state.password !== state.repassword && <>Passwords do not match.<br /></>}
                            <Turnstile siteKey='0x4AAAAAAAedjnJRu6bfBCM1' onSuccess={setTurnToken} />
                            <Button disabled={state.isValid === false} name="signup" variant={variant} type="submit" style={{ marginTop: "5px" }} onClick={handleCreation}>
                                Create Account
                            </Button>
                            <Button name="cancel" variant="danger" style={{ marginTop: "5px" }} onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6" className="d-none d-md-block">
                    </Col>
                    <Col sm="12" md="4">
                        The rental agreement must be signed and returned before you can make a reservation.
                    </Col>
                </Row>
            </Container>
        </>
    }

    return (
        <>
            <Navbar
                bg={variant.toLowerCase()}
                variant={variant.toLowerCase()}
                expand="lg">
                <Container>
                    <Navbar.Brand href="/">{L8N.siteTitle}</Navbar.Brand>
                </Container>
            </Navbar>
            <Container style={{ marginTop: "5px" }}>
                <Row>
                    <Col sm="12" md="6" className="d-none d-md-block" />
                    <Col sm="12" md="4">
                        {message && <>
                            <div className={'warningbox-' + variant}>
                                {message}
                            </div>
                        </>}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6" className="d-none d-md-block">
                        <img src="high-resolution-color-logo.png" width="50%" alt={L8N.siteTitle + ' Logo'} />
                    </Col>
                    <Col sm="12" md="4">
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="username" type="email" placeholder="Enter email" value={state.username} onChange={updateState} />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Password" value={state.password} onChange={updateState} />
                            </Form.Group>
                            <Turnstile siteKey='0x4AAAAAAAedjnJRu6bfBCM1' onSuccess={setTurnToken} />

                            <Button name="login" variant={variant} type="submit" style={{ marginTop: "5px" }} onClick={handleLogin}>
                                Login
                            </Button>
                            <Button name="signup" variant="secondary" style={{ marginTop: "5px" }} onClick={handleNewUser}>
                                Create Account
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <div className="shadow stickynote">
                        <h2>Back to School Special</h2>
                        $125/hour for All August Flights.<br />
                        <sub>*Flight must be completed between August 1, 2024 and August 30, 2024 and is only valid for N537FL</sub>
                    </div>
                    <Col sm="12" md="12" className="border-top text-center fs-3 mb-1">
                        Meet N537FL
                    </Col>
                    <Col sm="12" md="6" className="text-center">
                        <img src="images/537FL.jpeg" width="90%" alt="537FL Piper Cheroke" className="shadow p-3 mb-5 bg-body rounded" />
                    </Col>
                    <Col sm="12" md="4">
                        N537FL is a 1971 Piper Cheroke PA-28-140.<br />
                        Located at Taylor Airport (KTYL)<br />
                        Safe and meticulously maintained aircraft.<br />
                        Flexible scheduling to fit your needs.<br />
                        Rate: <s>$140/hour</s> <br /><b>August Special $125 / hour</b> (Fuel Included)<br />

                        <Button name="signup" className="btn btn-primary btn-lg" variant="success" style={{ marginTop: "5px" }} onClick={handleNewUser}>
                            Start Flying
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
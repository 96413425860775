import React from 'react'
import { L8N } from '../l8n'

const MissingContract = () => {
    return (
        <div style={{ backgroundColor: 'lightyellow', borderStyle: 'solid', borderWidth: 1, borderRadius: 5, margin: 5, width: '98%', textAlign: 'center' }}>
            <div dangerouslySetInnerHTML={{ __html: L8N.missingContract }} />
            <a href="/Rental_Agreement.pdf" target="_blank">Rental Contract</a>
        </div>
    )
}

export default MissingContract
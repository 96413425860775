import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, Navigate } from "react-router-dom";

import { Form, Button } from 'react-bootstrap';

import { DateTime } from 'luxon'
import { roles } from '../../reducers/auth';

import { useMutation, gql } from '@apollo/client';

export const CREATE = gql`
    mutation MaintenanceCreate($input: MaintenanceInput!) {
        MaintenanceCreate(input: $input) {
            id
        }
    }
`;

const SchedMaint = ({ variant }) => {
    const userRoles = useSelector(roles);
    const { id } = useParams();

    const [state, setState] = useState({
        catagory: '',
        dueAtHobbs: undefined,
        dueDate: '',
        request: '',
        tailNumber: id,
        completed: false,
    });

    const [createMaintenance, { loading }] = useMutation(CREATE, {
        onError: (e) => {
            console.error(e)
        },
    });

    if (userRoles.includes('admin') === false) return <>Unauthorized</>

    const handlecatagoryChange = (e) => {
        let request = '';
        switch (e.target.value) {
            case 'Oil Change':
                request = 'Oil Change';
                break;
            case '100Hr':
                request = '100 Hour Inspection';
                break;
            case 'Annual':
                request = 'Annual Inspection';
                break;
            case 'Avionics':
                request = 'Avionics Inspection';
                break;
            default:
                break;
        }
        setState({
            ...state,
            catagory: e.target.value,
            request,
        })
    }

    const handleDescriptionChange = (e) => {
        setState({
            ...state,
            request: e.target.value,
        })
    }
    const handleHobbChange = (e) => {
        setState({
            ...state,
            dueAtHobbs: parseInt(e.target.value),
        })
    }
    const handleDateChange = (e) => {
        setState({
            ...state,
            dueDate: e.target.value,
        })
    }

    const saveRequest = (event) => {
        event.preventDefault();
        const selectedDate = DateTime.fromISO(state.dueDate);

        let dueDateAsSeconds = undefined;
        if (selectedDate.isValid) {
            dueDateAsSeconds = selectedDate.toSeconds();
        }
        createMaintenance({
            variables: {
                input: {
                    tailNumber: state.tailNumber,
                    catagory: 'Regular Maintenance',
                    dueAtHobbs: state.dueAtHobbs,
                    dueDate: dueDateAsSeconds,
                    request: state.request,
                }
            },
            onCompleted() {
                setState({
                    ...state,
                    completed: true,
                })
            },
            onError(error) {
                console.error(error);
            }
        });
    }
    if (state.completed) {
        return <Navigate to={`/admin/aircraft/${id}`} replace={true} />
    }

    if (loading) return <>
        Loading...
    </>

    return (
        <>
            <h3>Schedule Maintenance</h3>
            <Form.Group className="mb-3">
                <Form.Label>Catagory</Form.Label>
                <Form.Select name="catagory" value={state.catagory} onChange={handlecatagoryChange}>
                    <option value=''>-Select a Catagory-</option>
                    <option value='Oil Change'>Oil Change</option>
                    <option value='100Hr'>100 Hour Inspection</option>
                    <option value='Annual'>Annual Inspection</option>
                    <option value='Avionics'>Avionics Inspection</option>
                    <option value='Other'>Other</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} name="request" onChange={handleDescriptionChange} value={state.request} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Due At Hobbs Time</Form.Label>
                <Form.Control type='number' name="dueAtHobbs" onChange={handleHobbChange} value={state.dueAt} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Due By</Form.Label>
                <Form.Control type='date' name="dueAtDate" onChange={handleDateChange} value={state.dueDate} />
            </Form.Group>
            <div style={{ width: '100%', textAlign: 'right', paddingRight: '5px' }}>
                <Button name="cancel" variant={`${variant}-secondary`} href={`/admin/aircraft/${id}`} style={{ marginRight: '5px' }}>Cancel</Button>
                <Button name="save" variant={variant} onClick={saveRequest}>Save</Button>
            </div>
        </>
    )
}

export default SchedMaint
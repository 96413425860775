import { gql } from '@apollo/client';
//---QUERIES---
export const GET_RESERVATIONS_W_INFLIGHT = gql`
    query MyReservations {
        myReservations {
            id
            start
            end
            aircraft {
                tailNumber
                type
            }
        }
        inFlight {
            id
            aircraft {
                tailNumber
                type
            }
        }
        activeAgreements{
            tailNumber
        }
    }
`;

//---MUTATIONS---
export const CREATE = gql`
    mutation ReservationCreate {
        ReservationCreate {
            id
        }
    }
`;

export const SET_AIRCRAFT = gql`
    mutation ReservationCreate($reservationId: ID!, $tailNumber: ID!) {
        ReservationSetAircraft(id: $reservationId, tailNumber: $tailNumber) {
            id
            aircraft {
                tailNumber
                status
            }
        }
    }
`;

export const SET_DATE = gql`
    mutation ReservationSetDate($reservationId: ID!, $date: Date!) {
        ReservationSetDate(id: $reservationId, date: $date) {
            id
        }
    }
`;

export const SET_TIMES = gql`
    mutation Mutation($reservationId: ID!, $startTime: Date!, $endTime: Date!) {
        ReservationSetTime(id: $reservationId, startTime: $startTime, endTime: $endTime) {
            id
        }
    }
`;

export const CANCEL = gql`
    mutation ReservationCancel($reservationCancelId: ID!) {
        ReservationCancel(id: $reservationCancelId) {
            stage
        }
    }
`;

export const FINILIZE = gql`
    mutation ReservationFinialize($reservationId: ID!) {
        ReservationFinialize(id: $reservationId) {
            id
            stage
            start
            end
            aircraft{
                tailNumber
                type
            }
        }
    }
`;
import React, { useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/DeleteTwoTone';
import Modal from 'react-bootstrap/Modal';

import { useQuery, useMutation, gql } from '@apollo/client';

const GET_CARDS_GQL = gql`
    query {
        paymentMethods {
            id
            last4
            brand
        }
    }
`;

const DELETE_CARD_GQL = gql`
    mutation DeleteCard($paymentId: String!) {
        DeleteCard(paymentId: $paymentId) {
            id
        }
    }
`;

const Billing = ({ variant }) => {
    const [loading, setLoading] = useState(false)
    const { loading: cardLoading, data, refetch } = useQuery(GET_CARDS_GQL);
    const [deleteCard, { loading: deleteLoading }] = useMutation(DELETE_CARD_GQL);

    const [deleteState, setDeleteState] = useState({
        showDelete: false
    });

    if (loading || cardLoading || deleteLoading) return <>
        Loading...
    </>

    const handleDelete = (card) => {
        setDeleteState({
            showDelete: true,
            card
        });
    }

    const DeleteModal = () => {
        const handleClose = () => {
            setDeleteState({
                showDelete: false
            })
        }

        const handleDelete = () => {
            setLoading(true)
            deleteCard({
                variables: {
                    paymentId: deleteState.card.id
                },
                onCompleted(data) {
                    refetch()
                        .then(() => setLoading(false))
                    setDeleteState({
                        showDelete: false
                    })
                },
                onError(error) {
                    console.error(error);
                }
            });
        }

        if (!deleteState.card) return;

        return <Modal show={deleteState.showDelete} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>Your are about to remove your {deleteState.card.brand} ending in {deleteState.card.last4}. Are you sure about this?</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete Card
                </Button>
            </Modal.Footer>
        </Modal>
    }

    return (
        <Container>
            <DeleteModal />
            <Row>
                <Col lg="6">
                    <Table striped variant={variant.toLowerCase()}>
                        <thead>
                            <th>Card</th>
                            <th></th>
                            <th></th>
                        </thead>
                        <tbody>
                            {data.paymentMethods.map((card) => (
                                <tr>
                                    <td>**** {card.last4}</td>
                                    <td className='cap'>{card.brand}</td>
                                    <td>
                                        <CloseIcon onClick={() => handleDelete(card)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col xs={12}>
                    <Button variant={variant} href="/billing/addcard">Add Card</Button>
                </Col>
            </Row>

        </Container>

    )
}

export default Billing
import React, { useState } from 'react'

import { DateTime } from 'luxon';
import { useParams } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { useQuery, useMutation, gql } from '@apollo/client';
import FlightDetails from '../../components/FlightDetails';
import OpenMaintenance from '../../components/OpenMaintenance';
import { useSelector } from 'react-redux';
import { flags } from '../../reducers/auth';

const GET_RESERVATION = gql`
    query Reservation($reservationId: String!) {
        reservation(id: $reservationId) {
            id
            start
            end
            stage
            flight {
                id
                hours
                invoice {
                    id
                    invoicedAmount
                    invoicedHours
                    prePaidAmount
                    prePaidHours
                    totalAmount
                }
                paymentMethod {
                    id
                    brand
                    last4
                }
            }
            aircraft {
                tailNumber
                type
                openMaintenance {
                    id
                    status
                    request
                    catagory
                    dueAtHobbs
                    dueDate
                }
            }
        }
        paymentMethods {
            id
            brand
            last4
        }
    }
`;

const CHECKOUT_GQL = gql`
    mutation StartFlight($input: CheckInput!) {
        startFlight(input: $input) {
            hobbOut
            tachOut
            id
            stage
        }
    }
`;

const CHECKIN_GQL = gql`
    mutation EndFlight($input: CheckInput!) {
        endFlight(input: $input) {
            id
            hours
            start
            end
            stage
            aircraft {
                tailNumber
                type
            }
            invoice {
                id
                invoicedAmount
                invoicedHours
                prePaidAmount
                prePaidHours
                totalAmount
            }
        }
    }
`;

const SET_PAYMENT_GQL = gql`
    mutation FlightSetPaymentMethod($reservationId: ID!, $paymentId: String!) {
        flightSetPaymentMethod(reservationId: $reservationId, paymentId: $paymentId) {
            paymentMethod {
                id
            }
        }
    }
`;

const Checkout = ({ variant = 'light' }) => {
    const { id } = useParams();
    const userFlags = useSelector(flags);

    const [checkoutGQL, { loading: checkoutLoading }] = useMutation(CHECKOUT_GQL);
    const [setPaymentMethod, { loading: setPaymentMethodLoading }] = useMutation(SET_PAYMENT_GQL);
    const [checkinGQL, { loading: checkinLoading }] = useMutation(CHECKIN_GQL, {
        onError: (error) => {
            setError(error.message);
        }
    });

    const { loading, error: reservationError, data: reservationData, refetch } = useQuery(GET_RESERVATION, {
        variables: {
            reservationId: id
        },
        onCompleted: ({ reservation, paymentMethods }) => {
            setState({
                ...state,
                label: reservation.stage === 'RESERVED' ? 'Check-Out' : 'Check-In',
                inflight: reservation.stage === 'INFLIGHT',
                showInflight: reservation.stage === 'INFLIGHT',
                paymentMethod: reservation.flight.paymentMethod,
                paymentMethods
            })
        },
    });


    const [error, setError] = useState('');

    const [state, setState] = useState({
        label: 'Check-out',
        hobb: '',
        tach: '',
        inflight: false,
        isValid: false,
        maintAck: false,
        showInflight: false,
        paymentMethods: []
    })

    const reload = async (oldstate) => {
        refetch().then(({ data }) => {
            const newstate = {
                ...oldstate,
                label: data.reservation.stage === 'RESERVED' ? 'Check-Out' : 'Check-In',
                inflight: data.reservation.stage === 'INFLIGHT',
                paymentMethod: data.reservation.flight.paymentMethod,
            };

            setState(validate(newstate));
        })
    }

    const handleTachChange = (event) => {
        const newState = {
            ...state,
            tach: event.target.value
        };
        setState(validate(newState));
    }

    const handleHobbChange = (event) => {
        const newState = {
            ...state,
            hobb: event.target.value
        };
        setState(validate(newState));
    }

    const handleMaintAck = () => {
        const newState = {
            ...state,
            maintAck: true
        };
        setState(validate(newState));
    }

    const validate = (currentState) => {
        if (!currentState.hobb || !currentState.tach) {
            setError('Tach and hobbs are required.');
            return {
                ...currentState,
                isValid: false
            };
        }
        if (currentState.inflight === false && reservationData.reservation.aircraft.openMaintenance.length > 0 && !currentState.maintAck) {
            setError('Please read and acknowledge maintenance requests.');
            return {
                ...currentState,
                isValid: false
            };
        }

        if (!currentState.paymentMethod) {
            setError('Please select a payment method.');
            return {
                ...currentState,
                isValid: false
            };
        }

        setError('');
        return {
            ...currentState,
            isValid: true
        };
    }


    const handleCheck = () => {
        if (reservationData.reservation.stage === 'RESERVED') {
            checkoutGQL({
                variables: {
                    input: {
                        hobb: parseFloat(state.hobb),
                        reservationId: id,
                        tach: parseFloat(state.tach),
                    }
                },
                onCompleted(data) {
                    reload({
                        ...state,
                        showInflight: true,
                        hobb: '',
                        tach: ''
                    })
                }
            });

        } else {
            checkinGQL({
                variables: {
                    input: {
                        hobb: parseFloat(state.hobb),
                        reservationId: id,
                        tach: parseFloat(state.tach),
                    }
                },
                onCompleted(data) {
                    reload(state);
                }
            });
        }
    }

    if (loading || checkoutLoading || checkinLoading) return <>
        Loading...
    </>;
    if (reservationError) return `Error! ${reservationError}`;

    const InFlightModal = () => {
        const handleClose = () => {
            setState({
                ...state,
                showInflight: false
            });
        }

        return <Modal show={state.showInflight} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Enjoy your flight</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>When you are done come back here and check-in</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={variant} onClick={handleClose}>
                    Start Check-in
                </Button>
            </Modal.Footer>
        </Modal>
    }

    const cardChange = (event) => {
        const paymentId = event.target.value;
        setPaymentMethod({
            variables: {
                paymentId,
                reservationId: id
            },
            onCompleted: () => {
                reload(state);
            },
            onError: (error) => {
                console.log(error.message);
            }
        })
    }

    if (reservationData.reservation.stage === 'COMPLETED') {
        return <Container>
            <span id="message">Your flight details have been recorded.</span>
            <Table striped bordered variant={variant.toLowerCase()} responsive="sm">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Aircraft</th>
                        <th>Hours</th>
                        {userFlags.includes('prepay') && <th>Prepaid</th>}
                        <th>Billed to Card</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {DateTime.fromSeconds(reservationData.reservation.start).toLocaleString(DateTime.DATE_SHORT)}
                        </td>
                        <td>
                            {reservationData.reservation.aircraft.tailNumber}<br />
                            {reservationData.reservation.aircraft.type}
                        </td>
                        <td>
                            {reservationData.reservation.flight.hours}
                        </td>
                        {userFlags.includes('prepay') &&
                            <td>
                                ${reservationData.reservation.flight.invoice.prePaidAmount.toFixed(2)}
                            </td>
                        }
                        <td>
                            ${reservationData.reservation.flight.invoice.invoicedAmount.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    }

    return (
        <Form>
            <InFlightModal />
            <Container>
                <Row>
                    <Col>
                        <FlightDetails flightData={reservationData.reservation} variant={variant}></FlightDetails>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <Form.Label htmlFor='tach'>{state.label} Tach</Form.Label>
                        <Form.Control
                            type='number'
                            id='tach'
                            onChange={handleTachChange}
                            value={state.tach}
                        />
                    </Col>
                    <Col lg={3}>
                        <Form.Label htmlFor='hobbs'>{state.label} Hobbs</Form.Label>
                        <Form.Control
                            type='number'
                            id='hobbs'
                            onChange={handleHobbChange}
                            value={state.hobb}
                        />
                    </Col>
                </Row>

                <Row style={{ marginTop: '10px' }}>
                    <Col>
                        {state.inflight === false &&
                            <OpenMaintenance reservationId={id} variant={variant} requests={reservationData.reservation.aircraft.openMaintenance} ack={handleMaintAck} />
                        }
                        <div className={'warning-' + variant}>{error}</div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col md={6} lg={3}>
                        <div class="notice">
                            <Form.Label>Payment Method</Form.Label>
                            <Form.Select id="payment" onChange={cardChange} value={reservationData.reservation.flight.paymentMethod ? reservationData.reservation.flight.paymentMethod.id : ''} >
                                <option value=''> -Select a Card- </option>

                                {state.paymentMethods.map((card) => (
                                    <option key={card.id} value={card.id}>{card.brand} - {card.last4}</option>
                                ))}
                            </Form.Select>
                            At the conculsion of your flight your card wil be charged.
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col>
                        <Button name="submit" variant={variant} onClick={handleCheck} disabled={!state.isValid}>{state.label}</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}

Checkout.propTypes = {}

export default Checkout
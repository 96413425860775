import React from 'react'

import { useRouteError } from "react-router-dom";

const ErrorPage = props => {
    const error = useRouteError();
    console.error(error);

    if (error.status === 404) {
        return <div id="error-page" style={{ margin: '3%' }}>
            <h1>Oops!</h1>
            <p>This page is missing. Maybe try looking in the hanger.</p>
        </div>
    }
    return (
        <div id="error-page" style={{ margin: '3%' }}>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

ErrorPage.propTypes = {}

export default ErrorPage
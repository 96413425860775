import React from 'react'

const Footer = () => {
    return (
        // <!-- Footer -->
        <footer className="text-center text-lg-start bg-body-tertiary text-muted">


            {/* <!-- Copyright --> */}
            <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05);" }}>
                © 2024 Copyright: Cleared Skies, LLC
            </div>
            {/* <!-- Copyright --> */}
        </footer>
        // <!-- Footer -->
    )
}

export default Footer
import React from 'react'
import { DateTime } from 'luxon';
import { Col, Row } from 'react-bootstrap';

const FlightDetails = ({ flightData, loading, variant }) => {
    if (loading) {
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <span className="label">Date:</span>
                        <div style={{ width: '6em', display: 'inline-block', borderRadius: '5px' }} className={'holder-' + variant}>&nbsp;</div>
                    </Col>
                    <Col xs={12}>
                        <span className="label">Aircraft:</span>
                        <div style={{ width: '6em', display: 'inline-block', borderRadius: '5px' }} className={'holder-' + variant}>&nbsp;</div>
                    </Col>
                    <Col xs={12}>
                        <span className="label">Time:</span>
                        <div style={{ width: '6em', display: 'inline-block', borderRadius: '5px' }} className={'holder-' + variant}>&nbsp;</div>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            {flightData &&
                <Row>
                    <Col xs={12}>
                        <span className="label">Date:</span> {DateTime.fromSeconds(flightData.start).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                    </Col>
                    <Col xs={12}>
                        <span className="label">Aircraft:</span> {flightData.aircraft.tailNumber} - {flightData.aircraft.type}
                    </Col>
                    <Col xs={12}>
                        <span className="label">Time:</span> {DateTime.fromSeconds(flightData.start).toLocaleString(DateTime.TIME_24_SIMPLE)} to {DateTime.fromSeconds(flightData.end).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </Col>
                </Row>
            }
        </>
    )
}

export default FlightDetails
import { gql } from '@apollo/client';
//---QUERIES---

// export const GET_AVAILABLE_AIRCRAFT = gql`
//     query Aircraft {
//         activeAgreements{
//             tailNumber
//             hourlyRate
//             prepaidRate
//             aircraft {
//                 type
//                 status
//                 openMaintenance {
//                     id
//                     status
//                     request
//                     catagory
//                     dueAtHobbs
//                     dueDate
//                 }
//             }
//         }
//     }
// `;

export const GET_ALL_AIRCRAFT = gql`
    query Aircraft {
        allAircraft {
            tailNumber
            type
            status
        }
    }
`;

export const GET_AIRCRAFT = gql`
    query Aircraft($tailNumber: String!) {
        aircraft(tailNumber: $tailNumber) {
            tailNumber
            type
            status
        }
    }
`;

export const GET_AIRCRAFT_W_MAINT = gql`
    query Aircraft($tailNumber: String!) {
        aircraft(tailNumber: $tailNumber) {
            tailNumber
            type
            status
            openMaintenance {
                id
                status
                request
                catagory
                dueAtHobbs
                dueDate
            }
        }
    }
`;

export const GET_SLOTS = gql`
    query AvailabileSlots($tailNumber: String!, $date: Date!) {
        availabileSlots(tailNumber: $tailNumber, date: $date) {
            datetime
            id
            reserved
            selected
        }
    }
`;
import React, { useState } from 'react'
import { Button, Col, Container } from 'react-bootstrap';
import Modal from 'react-modal';
import { useMutation, gql } from '@apollo/client';
import MaintenanceList from './MaintenanceList';

export const ACKNOWLEDGE_MAINTENANCE = gql`
    mutation AcknowledgeMaintenance($reservationId: ID!) {
        AcknowledgeMaintenance(reservationId: $reservationId) {
            id
        }
    }
`

const OpenMaintenance = ({ variant, reservationId, requests, ack }) => {
    const [ackMain, { loading }] = useMutation(ACKNOWLEDGE_MAINTENANCE);

    const [display, setDisplay] = useState(false);

    const handleShowModal = () => {
        setDisplay(true);
    }
    const handleCloseModal = () => {
        setDisplay(false);

        ackMain({
            variables: {
                reservationId
            },
            onCompleted() {
                if (ack) ack();
            }
        });
    }

    return (
        <>
            {(requests && requests.length > 0) &&
                <>
                    <Col>
                        This aircraft has open maintenance requests.<br />
                        <b>You are responsible for ensuring the aircraft is flight worthy.</b>
                    </Col>
                    <Col>
                        <Button id="view" className={variant} variant="link" onClick={handleShowModal}>View the open maintenance requests to ensure they will not interfere with your flight.</Button>
                    </Col>
                    <Modal
                        isOpen={display}
                        contentLabel="Open Maintenance"
                        onRequestClose={handleCloseModal}
                        className={'modal-' + variant}
                    >
                        <div className={'warningbox-' + variant}>
                            This aircraft has open maintenance requests.<br />
                            <b>You are responsible for ensuring the aircraft is flight worthy.</b><br />
                            Please check these maintenance requests to ensure they will not interfere with your flight.
                            <MaintenanceList variant={variant} requests={requests} />
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Button id="acknowledge" disabled={loading} onClick={handleCloseModal} variant={variant}>Acknowledge</Button>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </>
    )
}

export default OpenMaintenance
import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

const GET_MAINT = gql`
    query Maintenance($maintenanceId: ID!) {
        maintenance(id: $maintenanceId) {
            id
            aircraft {
                tailNumber
            }
            catagory
            createdDate
            dueDate
            dueAtHobbs
            request
            status
        }
    }
`;

const SCHEDULE = gql`
    mutation MaintenanceSchedule($maintenanceScheduleId: ID!, $startTime: Date!, $endTime: Date!) {
        MaintenanceSchedule(id: $maintenanceScheduleId, startTime: $startTime, endTime: $endTime) {
            id
            status
        }
    }
`

const START = gql`
    mutation MaintenanceStart($maintenanceStartId: ID!, $startDate: Date!) {
        MaintenanceStart(id: $maintenanceStartId, startDate: $startDate) {
            id
            status
        }
    }
`
const COMPLETE = gql`
    mutation MaintenanceComplete($maintenanceCompleteId: ID!, $completedDate: Date!, $hobb: Float!, $tach: Float!) {
        MaintenanceComplete(id: $maintenanceCompleteId, completedDate: $completedDate, hobb: $hobb, tach: $tach) {
            id
            status
        }
    }
`

const CANCEL = gql`
    mutation MaintenanceCancel($maintenanceCancelId: ID!, $reason: String!) {
        MaintenanceCancel(id: $maintenanceCancelId, reason: $reason) {
            id
            status
        }
    }
`

const ViewMaintAdmin = () => {
    const { id } = useParams();
    const [state, setState] = useState({
        show: null,
        startDate: DateTime.now().toISODate(),
        completeDate: DateTime.now().toISODate(),
        reason: '',
        hobb: '',
        tach: ''
    })

    const [ScheduleMaint, { loading: loadSchedule }] = useMutation(SCHEDULE);
    const [startMaint, { loading: loadStart }] = useMutation(START);
    const [completeMaint, { loading: loadComplete }] = useMutation(COMPLETE);
    const [cancelMaint, { loading: loadCancel }] = useMutation(CANCEL);

    const { loading, data } = useQuery(GET_MAINT, {
        variables: {
            maintenanceId: id
        },
        onError: console.error
    });

    const DataDisplay = ({ label, value }) => {
        return (
            <>
                <div className="label">{label}</div>
                <div className="data">{value}&nbsp;</div>
            </>
        )
    }

    const openModal = (event) => {
        setState({
            ...state,
            show: event.currentTarget.name,
        })
    }

    const closeModal = () => {
        setState({
            ...state,
            show: null,
        })
    }

    const handleStateChange = (event) => {
        setState({
            ...state,
            [event.currentTarget.name]: event.currentTarget.value,
        });
    }

    const handleSchedule = () => {
        ScheduleMaint({
            variables: {
                maintenanceScheduleId: id,
                startTime: DateTime.fromISO(state.startTime).toSeconds(),
                endTime: DateTime.fromISO(state.endTime).toSeconds()
            },
            onCompleted: () => {
                closeModal();
            }
        });
    }

    const handleStart = () => {
        startMaint({
            variables: {
                maintenanceStartId: id,
                startDate: DateTime.fromISO(state.startDate).toSeconds(),
            },
            onCompleted: () => {
                closeModal();
            }
        });
    }

    const handleCancel = () => {
        cancelMaint({
            variables: {
                maintenanceCancelId: id,
                reason: state.reason,
            },
            onCompleted: () => {
                closeModal();
            }
        });
    }

    const handleComplete = () => {
        completeMaint({
            variables: {
                maintenanceCompleteId: id,
                completedDate: DateTime.fromISO(state.completeDate).toSeconds(),
                hobb: parseFloat(state.hobb),
                tach: parseFloat(state.tach)
            },
            onCompleted: () => {
                closeModal();
            }
        });
    }

    //TODO: Wire up buttons to backend

    if (loading || loadCancel || loadSchedule || loadStart || loadComplete) return <>
        Loading...
    </>

    if (state.show === 'sched') {
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <DataDisplay label="Aircraft" value={data.maintenance.aircraft.tailNumber} />
                    </Col>
                    <Col xs={12}>
                        <DataDisplay label="Description" value={data.maintenance.request} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control type='datetime-local' name="startTime" onChange={handleStateChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control type='datetime-local' name="endTime" onChange={handleStateChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <Button variant="primary" onClick={handleSchedule}>
                            Save
                        </Button>
                    </Col>
                    <Col xs={2}>
                        <Button variant="secondary" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (state.show === 'start') {
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <DataDisplay label="Aircraft" value={data.maintenance.aircraft.tailNumber} />
                    </Col>
                    <Col xs={12}>
                        <DataDisplay label="Description" value={data.maintenance.request} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type='date' name="startDate" value={state.startDate} onChange={handleStateChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <Button variant="primary" onClick={handleStart}>
                            Save
                        </Button>
                    </Col>
                    <Col xs={2}>
                        <Button variant="secondary" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (state.show === 'complete') {
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <DataDisplay label="Aircraft" value={data.maintenance.aircraft.tailNumber} />
                    </Col>
                    <Col xs={12}>
                        <DataDisplay label="Description" value={data.maintenance.request} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Complete Date</Form.Label>
                            <Form.Control type='date' name="completeDate" value={state.completeDate} onChange={handleStateChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tach</Form.Label>
                            <Form.Control type='number' name="tach" onChange={handleStateChange} value={state.tach} />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Hobb</Form.Label>
                            <Form.Control type='number' name="hobb" onChange={handleStateChange} value={state.hobb} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={2}>
                        <Button variant="primary" onClick={handleComplete}>
                            Save
                        </Button>
                    </Col>
                    <Col xs={2}>
                        <Button variant="secondary" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (state.show === 'cancel') {
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <DataDisplay label="Aircraft" value={data.maintenance.aircraft.tailNumber} />
                    </Col>
                    <Col xs={12}>
                        <DataDisplay label="Description" value={data.maintenance.request} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span className="warning">WARNING: This will cancel the maintenance request. This cannot be undone.</span>
                    </Col>
                    <Col xs={12}>
                        <Form.Label htmlFor='reason'>Cancel Reason</Form.Label>
                        <Form.Select name="reason" value={state.reason} onChange={handleStateChange}>
                            <option value=''>-Select a Reason-</option>
                            <option value='Not Repairing'>Not Repairing</option>
                            <option value='Not Broken'>Not Broken</option>
                            <option value='Opened New Request'>Opened New Request</option>
                            <option value='Test'>Test</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Button variant="danger" onClick={handleCancel}>
                            Continue
                        </Button>
                    </Col>
                    <Col xs={3}>
                        <Button variant="primary" onClick={closeModal}>
                            Go Back
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <DataDisplay label="Aircraft" value={data.maintenance.aircraft.tailNumber} />
                </Col>
                <Col xs={12}>
                    <DataDisplay label="Catagory" value={data.maintenance.catagory} />
                </Col>
                <Col xs={12}>
                    <DataDisplay label="Description" value={data.maintenance.request} />
                </Col>
                <Col xs={12}>
                    <DataDisplay label="Status" value={data.maintenance.status} />
                </Col>
                <Col xs={12}>
                    <DataDisplay label="Due At Hobbs Time" value={data.maintenance.dueAtHobbs} />
                </Col>
                <Col xs={12}>
                    <DataDisplay label="Due By" value={data.maintenance.dueAtDate} />
                </Col>
            </Row>

            {data.maintenance.status === 'Open' &&
                <Button variant="primary" name="sched" onClick={openModal}>
                    Schedule
                </Button>
            }

            {data.maintenance.status === 'Scheduled' &&
                <Button variant="primary" name="sched" onClick={openModal}>
                    Re-Schedule
                </Button>
            }

            {(data.maintenance.status === 'Open' || data.maintenance.status === 'Scheduled') &&
                <Button variant="success" name="start" onClick={openModal}>
                    Start
                </Button>
            }

            {data.maintenance.status === 'In Progress' &&
                <Button variant="primary" name="complete" onClick={openModal}>
                    Complete Maintenance
                </Button>
            }

            {data.maintenance.status !== 'Completed' && data.maintenance.status !== 'Canceled' &&
                <Button variant="danger" name="cancel" onClick={openModal}>
                    Cancel Request
                </Button>
            }

        </Container>
    )
}

export default ViewMaintAdmin
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';

import '../styles/Reservation.css'

import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { SET_AIRCRAFT, SET_DATE, SET_TIMES, FINILIZE } from '../gql/Reservations';
import { GET_SLOTS } from '../gql/Aircraft';
import { DateTime } from 'luxon'
import FlightDetails from '../components/FlightDetails';
import OpenMaintenance from '../components/OpenMaintenance';
import Dashboard from './Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import { flags } from '../reducers/auth';
import { L8N } from '../l8n';
import MissingContract from '../components/MissingContract';

export const GET_AVAILABLE_AIRCRAFT = gql`
    query Aircraft($reservationId: String!) {
        activeAgreements{
            tailNumber
            hourlyRate
            prepaidRate
            aircraft {
                type
                status
                openMaintenance {
                    id
                    status
                    request
                    catagory
                    dueAtHobbs
                    dueDate
                }
            }
        }
        prepay {
            id
            credit
        }
        reservation(id: $reservationId) {
            id
            stage
            start
            end
            aircraft {
                tailNumber
                status
                type
            }
        }
        paymentMethods {
            id
            brand
            last4
        }
    }
`;

const NewReservation = ({ variant = 'lighter' }) => {
    const userFlags = useSelector(flags);

    const { id } = useParams();

    const [state, setState] = useState({
        message: '',
        error: '',
        completed: false,
        hasBilling: true,
    })

    const [dayInfo, setDayInfo] = useState({
        selectedISO: undefined
    });

    const [aptTime, setAptTime] = useState({
        start: undefined,
        end: undefined,
        select: 'start'
    });
    const [aircraftData, setAircraftData] = useState({});


    const { loading: loadingAircraft, data: craftdata } = useQuery(GET_AVAILABLE_AIRCRAFT, {
        variables: { reservationId: id },
        onCompleted: async (data) => {
            if (data.paymentMethods.length === 0) {
                setState({
                    ...state,
                    hasBilling: false
                })
                return;
            }

            if (data.reservation.stage !== 'OPEN') {
                setReservationData(data.reservation);
                setState({
                    ...state,
                    completed: true,
                    message: `Your reservation has been confirmed.`
                })
                return;
            }

            if (!data.reservation.aircraft) return

            setAircraftData(data.reservation.aircraft);

            if (!data.reservation.start) return;

            const selectedDate = DateTime.fromSeconds(data.reservation.start);
            handleDateChange({
                target: { value: selectedDate.toISODate() }
            }, {
                runUpdate: false,
                tailNumber: data.reservation.aircraft.tailNumber
            })
        },
        onError: (error) => {
            console.error(error);

        }
    });


    const [setAircraft, { loading: loadSetAircraft }] = useMutation(SET_AIRCRAFT);

    const [getSlots, { loading: loadingSlots, error }] = useLazyQuery(GET_SLOTS, {
        fetchPolicy: 'network-only'
    });

    const [setDate, { loading: loadSetDate }] = useMutation(SET_DATE);
    const [setTimes] = useMutation(SET_TIMES);

    const [reservationData, setReservationData] = useState();

    const [saveReservationGQL, { loading: loadSave }] = useMutation(FINILIZE, {
        onCompleted: (data) => {
            setReservationData(data.ReservationFinialize)
        },
        onError: (error) => {
            setState({
                ...state,
                error: error.message
            });
        }
    });

    useEffect(() => {
        setState({
            message: '',
            error: '',
            completed: false
        })
        setDayInfo({
            selectedISO: undefined
        });
        setAptTime({
            start: undefined,
            end: undefined,
            select: 'start'
        });
        setAircraftData({});
    }, [id])


    const populateTimeSlots = (dayInfo, tailNumber) => {
        return new Promise((resolve, reject) => {
            getSlots(
                {
                    variables: { tailNumber, date: dayInfo.toSeconds() },
                    onCompleted({ availabileSlots }) {
                        const slots = [];
                        availabileSlots.forEach(element => {
                            slots.push({
                                ...element,
                            })
                        });
                        return resolve(slots);
                    }
                }
            )
        })
    }

    const aircraftChange = (event) => {
        const tailNumber = event.target.value;
        setAircraft({
            variables: {
                reservationId: id,
                tailNumber
            },
            onCompleted: ({ ReservationSetAircraft }) => {
                setAircraftData(ReservationSetAircraft.aircraft);
            }
        })
    }

    const slotClick = ({ slot }) => {
        if (slot.reserved) return;
        const newDays = { ...dayInfo };
        const slotIndex = newDays.slots.findIndex(t => t.id === slot.id);

        let startIndex = slotIndex;
        let endIndex = slotIndex;

        if (aptTime.select === 'start') {
            startIndex = slotIndex;
        } else {
            startIndex = aptTime.start.slotIndex;
        }

        setSelected(newDays, { startIndex, endIndex });
        setDayInfo(newDays);
    }

    const setSelected = (newDays, { startIndex, endIndex }) => {
        if (startIndex === undefined || endIndex === undefined) return;
        if (endIndex < startIndex) {
            const temp = startIndex;
            startIndex = endIndex;
            endIndex = temp;
        }

        for (let index = 0; index < newDays.slots.length; index++) {
            newDays.slots[index].selected = false;
        }

        const newState = { ...state };
        newState.error = ''

        for (let index = startIndex; index <= endIndex; index++) {
            if (newDays.slots[index].reserved) {
                newState.error = 'Your reservation overlaps a reserved time slot. It has been shortened.'
                endIndex = index;
                break;
            }
            newDays.slots[index].selected = true;
        }

        const select = aptTime.select === 'start' ? 'end' : 'start';
        const startTime = newDays.slots[startIndex].datetime;
        const endTime = newDays.slots[endIndex + 1].datetime;

        newState.message = `Your reservation will be ${dayInfo.date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} 
        from ${DateTime.fromSeconds(startTime).toLocaleString(DateTime.TIME_24_SIMPLE)} - ${DateTime.fromSeconds(endTime).toLocaleString(DateTime.TIME_24_SIMPLE)}`;

        setState(newState);

        setAptTime({
            start: {
                ...newDays.slots[startIndex],
                slotIndex: startIndex
            },
            end: {
                ...newDays.slots[endIndex],
                slotIndex: endIndex
            },
            select
        });

        setTimes({
            variables: {
                "reservationId": id,
                "startTime": startTime,
                "endTime": endTime,
            },
            onError: (err) => console.error(err),
        })

        return newDays;
    }

    const handleDateChange = async (event, props) => {
        let tailNumber = aircraftData.tailNumber;
        if (props) {
            tailNumber = props.tailNumber;
        }
        // const { runUpdate = true, tailNumber = aircraftData.tailNumber } = props;

        const newState = {
            ...state,
            message: undefined,
            error: undefined
        };

        const selectedDate = DateTime.fromISO(event.target.value);
        if (selectedDate.invalid || selectedDate.year < 1900) {
            return;
        }

        if (selectedDate < DateTime.fromObject({ hour: 0 }, { zone: 'America/Phoenix' })) {
            setDayInfo({
                selectedISO: undefined,
            });
            newState.error = 'It looks like you are trying to travel into the past. This aircraft hasn\'t been equipped with a flux capicitor yet.';
            setState(newState);
            return;
        }

        setState(newState);

        setDayInfo({
            date: selectedDate,
            selectedISO: event.target.value,
            slots: await populateTimeSlots(selectedDate, tailNumber),
        })

        if (!props || props.runUpdate) {
            setDate({
                variables: {
                    reservationId: id,
                    date: selectedDate.toSeconds()
                }
                , onError: (error) => console.error(error),
            })
        }
    }

    const saveReservation = (event) => {
        saveReservationGQL({
            variables: {
                reservationId: id
            },
            onCompleted: (data) => {
                setState({
                    ...state,
                    completed: true,
                    message: `Your reservation has been confirmed.`
                })
            }
        });
    }

    const Header = ({ credit }) => {
        return <>
            <h1>New Reservation</h1>
            {userFlags.includes('prepay') &&
                <Row>
                    <Col xs={7} md={12}>
                        <b>Prepaid Credit: </b>${credit ? credit : <div className={'holder-' + variant}>&nbsp;</div>}
                    </Col>
                    <Col xs={5} md={12}>
                        <a href={`/prepay?return=/reservation/${id}`}>Add Funds</a>
                    </Col>
                </Row>
            }
        </>
    }


    /** Conditional Renders */
    if (craftdata && (!craftdata.activeAgreements || craftdata.activeAgreements.length === 0)) {
        return <>
            <MissingContract />
            <Container>
                <Header />
                <Row>
                    <Col lg={6}>
                        <div>Aircraft</div>
                        <Form.Select name="aircraft" disabled={true} style={{ width: '100%' }}>
                            <option value=''></option>
                        </Form.Select>
                    </Col>
                </Row>
            </Container>
        </>
    }

    if (state.hasBilling == false) {
        return <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <span name="message">
                            <b>
                                Please add a credit card before starting a new reservation.
                            </b>
                        </span>
                    </Col>
                    <Col xs={12}>
                        <Button variant={variant} href="/billing/addcard">Add Card</Button>
                    </Col>
                </Row>
            </Container>
        </>
    }

    if (state.completed) {
        return <>
            <Container>
                <span name="message">
                    <b>
                        {state.message}
                    </b>
                </span>
                <Row>
                    <Col xs={12} sm={6}>
                        <FlightDetails flightData={reservationData} variant={variant}></FlightDetails>
                    </Col>
                </Row>
                <Row>
                    <Dashboard variant={variant} />
                </Row>
            </Container>
        </>
    }
    /** End Conditional Renders */
    const Slot = ({ slot }) => {
        let className = 'apptSlot ';
        if (slot.reserved) {
            className += 'reserved';
        } else if (slot.selected) {
            className += 'selected';
        }

        const asTime = DateTime.fromSeconds(slot.datetime).toLocaleString(DateTime.TIME_24_SIMPLE)

        return <Row>
            <Col xs={2}>
                <span className='time'>
                    {asTime}
                </span>
            </Col>
            <Col>
                <div id={slot.datetime} name={asTime} className={className} onClick={() => slotClick({ slot })}>
                </div>
            </Col>

        </Row>
    }

    if (loadingAircraft) {
        return <Container>
            <Header />
            <Row>
                <Col lg={6}>
                    <div>Aircraft</div>
                    <Form.Select name="aircraft" disabled={true} className={'holder-' + variant} style={{ width: '100%' }}>
                        <option value=''></option>
                    </Form.Select>
                </Col>
            </Row>
        </Container>
    }

    const DateSelector = () => {
        if (loadSetAircraft) {
            return <>
                <Col lg={6}>
                    <Form.Label htmlFor='startDate'>Date</Form.Label>
                    <Form.Control disabled={true} className={'holder-' + variant} style={{ width: '100%' }} />
                </Col>
            </>
        }
        return <>{
            aircraftData.tailNumber &&
            <Col lg={6}>
                <Form.Label htmlFor='startDate'>Date</Form.Label>
                <Form.Control
                    type='date'
                    id='startDate'
                    value={dayInfo.selectedISO}
                    onChange={handleDateChange}
                />

            </Col>
        }</>
    }

    const LoadingSlots = () => {
        if (!loadingSlots) return <></>
        return <div className='scroll'>
            <Container>
                {[...Array(20)].map((e, i) =>
                    <Row key={i}>
                        <Col xs={2}>
                            <span className={'time holder-' + variant}>
                                &nbsp;
                            </span>
                        </Col>
                        <Col>
                            <div className={'apptSlot holder-' + variant}>
                                &nbsp;
                            </div>
                        </Col>

                    </Row>
                )}
            </Container>
        </div>
    }

    if (error) return `Error! ${error}`;

    return (
        <>
            <Form>
                <Container>
                    <Header credit={craftdata.prepay.credit.toFixed(2)} />
                    <Row>
                        <Col lg={6}>
                            <Form.Label>Aircraft</Form.Label>
                            <Form.Select id="aircraft" value={aircraftData.tailNumber} onChange={aircraftChange}>
                                <option value=''>-Select an Aircraft-</option>
                                {craftdata.activeAgreements.map((agreement) => (
                                    <option key={agreement.tailNumber} value={agreement.tailNumber}>{agreement.tailNumber} - ${agreement.hourlyRate.toFixed(2)}/Hour {userFlags.includes('prepay') && <>(${agreement.prepaidRate.toFixed(2)}/Prepaid)</>}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <DateSelector />
                    </Row>
                    {aircraftData.status === 'Grounded' &&
                        <Row>
                            <Col>

                                <div className="warning">
                                    This aircraft is currently Grounded. It may not be availabile for your selected flight.<br />
                                    Please check the status of the aircraft before your flight.
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col lg={3} className="d-none d-lg-block">

                        </Col>
                        <Col lg={6}>
                            <LoadingSlots />

                            {!loadingSlots && dayInfo.selectedISO &&
                                <>
                                    <div className='scroll'>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    {dayInfo.slots.map((slot) => (
                                                        <Slot key={slot.id} slot={slot}></Slot>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </>
                            }
                        </Col>
                        <Col lg={3} className="d-none d-lg-block">

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className={'warning-' + variant}>{state.error}</div>
                        </Col>
                    </Row>
                    {state.message &&
                        <Row>
                            <Col name="message">
                                {state.message}
                            </Col>
                        </Row>
                    }
                    {aircraftData.tailNumber &&
                        <OpenMaintenance reservationId={id} variant={variant} requests={craftdata.activeAgreements.find(e => e.tailNumber === aircraftData.tailNumber).aircraft.openMaintenance} />
                    }
                    {dayInfo.selectedISO &&
                        <Row>
                            <Col style={{ marginTop: '10px' }}>
                                <Button id="save" disabled={loadSave} variant={variant} onClick={saveReservation}>Save Reservation</Button>
                            </Col>
                        </Row>
                    }
                </Container>
            </Form>
        </>
    )
}

NewReservation.propTypes = {}

export default NewReservation
import { createSlice } from '@reduxjs/toolkit'
import jwt from 'jsonwebtoken';

export const authSlice = createSlice({
    name: 'authintication',
    initialState: {
        value: localStorage.getItem('token'),
        roles: localStorage.getItem('roles'),
        flags: localStorage.getItem('flags'),
    },
    reducers: {
        setToken: (state, action) => {
            localStorage.setItem('token', action.payload);
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = action.payload;
        },
        setRoles: (state, action) => {
            localStorage.setItem('roles', action.payload);
            state.roles = action.payload
        },
        setFlags: (state, action) => {
            localStorage.setItem('flags', action.payload);
            state.flags = action.payload
        },
        setLogout: (state) => {
            localStorage.clear();
            state.value = undefined;
            state.roles = undefined;
        }
    },
})

export const isAuthinticated = ({ authintication }) => {
    if (!authintication.value || jwt.decode(authintication.value).exp * 1000 <= Date.now()) {
        localStorage.clear();
        return false
    }
    return true;
}

export const roles = ({ authintication }) => {
    if (isAuthinticated({ authintication }) === false) return;
    return authintication.roles;
}

export const flags = ({ authintication }) => {
    if (isAuthinticated({ authintication }) === false) return [];
    return authintication.flags ? authintication.flags : [];
}

// Action creators are generated for each case reducer function
export const { setToken, setRoles, setLogout, setFlags } = authSlice.actions

export default authSlice.reducer
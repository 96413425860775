import React from 'react'

import { Container, Row, Col } from 'react-bootstrap';

import { useQuery } from '@apollo/client';

import { gql } from '@apollo/client';

import Reservations from './Components/Reservations'
import AircraftList from './Components/AircraftList';
import { L8N } from '../../l8n';
import MissingContract from '../../components/MissingContract';

export const GET_AVAILABLE_AIRCRAFT = gql`
    query Aircraft {
        activeAgreements{
            tailNumber
            aircraft {
                type
                status
            }
        }
    }
`;

const Dashboard = ({ variant = 'lighter' }) => {
    const { loading: loadingCraft, data } = useQuery(GET_AVAILABLE_AIRCRAFT);

    return (
        <>
            <Container>
                {!loadingCraft && (!data.activeAgreements || data.activeAgreements.length === 0) &&
                    <Row>
                        <MissingContract />
                    </Row>
                }
                <Row>
                    <Col xs={12} lg={6}><Reservations variant={variant} /></Col>
                    <Col xs={12} lg={6}><AircraftList variant={variant} /></Col>
                </Row>
            </Container>
        </>
    )
}

export default Dashboard
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { isTestEnvironment } from "./l8n";

const uri = isTestEnvironment ? 'https://testatc.clearedskies.com/graphql' : 'https://atc.clearedskies.com/graphql';
// const uri = 'http://127.0.0.1:8081/graphql';
const httpLink = createHttpLink({
    uri,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const gqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default gqlClient;
import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';

const SET_DEFAULT = gql`
    mutation CreateCard($intentId: String!) {
        CreateCard(intentId: $intentId)
    }
`

const ReturnCard = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [setDefaultCard, { loading }] = useMutation(SET_DEFAULT);

    useEffect(() => {
        // Update the document title using the browser API
        const returnUrl = searchParams.get('ret');

        if (searchParams.get('default') === 'true') {
            setDefaultCard({
                variables: {
                    intentId: searchParams.get('setup_intent')
                },
                onCompleted(data) {
                    if (searchParams.get('ret')) {
                        return navigate(returnUrl);
                    }
                    console.log('data', data);
                },
                onError(error) {
                    console.log('error', error);
                    if (searchParams.get('ret')) {
                        return navigate(returnUrl);
                    }
                }
            });
        } else if (searchParams.get('ret')) {
            if (searchParams.get('ret')) {
                return navigate(returnUrl);
            }
        }
    }, [searchParams]);

    if (loading) return <>
        Loading...
    </>

    return (
        <div>
            {searchParams.get('ret')}
            Your card has been added to your account.<br />
        </div>
    )
}

export default ReturnCard
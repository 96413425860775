import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';

import { useMutation, useQuery, gql } from '@apollo/client';
import { L8N } from '../l8n';
import MissingContract from '../components/MissingContract';

export const GET_AVAILABLE_AIRCRAFT = gql`
    query Aircraft {
        activeAgreements{
            tailNumber
            hourlyRate
            prepaidRate
            aircraft {
                type
                status
                openMaintenance {
                    id
                    status
                    request
                    catagory
                    dueAtHobbs
                    dueDate
                }
            }
        }
    }
`;

export const CREATE = gql`
    mutation MaintenanceCreate($input: MaintenanceInput!) {
        MaintenanceCreate(input: $input) {
            id
        }
    }
`;

const Maintenance = ({ variant = 'lighter' }) => {
    const [state, setState] = useState({
        request: '',
        tailNumber: '',
        catagory: '',
        completed: false,
    });

    const [createMaintenance, { loading: loadCreate }] = useMutation(CREATE);

    const { loading: loadAircraft, data: craftdata } = useQuery(GET_AVAILABLE_AIRCRAFT, {
        onCompleted: (data) => {
            if (data.availableAircraft.length === 1) {
                aircraftChange({
                    target: {
                        value: data.availableAircraft[0].tailNumber
                    }
                })
            }
        }
    });

    const aircraftChange = (event) => {
        const tailNumber = event.target.value;
        setState(
            {
                ...state,
                tailNumber: tailNumber,
            }
        );
    }


    const handleDescriptionChange = (e) => {
        setState({
            ...state,
            request: e.target.value,
        })
    }

    const handlecatagoryChange = (e) => {
        setState({
            ...state,
            catagory: e.target.value,
        })
    }

    const saveRequest = (event) => {
        event.preventDefault();
        createMaintenance({
            variables: {
                input: {
                    tailNumber: state.tailNumber,
                    catagory: state.catagory,
                    request: state.request,
                }
            },
            onCompleted() {
                setState({
                    ...state,
                    completed: true,
                })
            }
        });
    }

    const AircraftSelection = () => {
        if (loadAircraft) {
            return <Form.Group className="mb-3">
                <Form.Label>Aircraft</Form.Label>
                <Form.Select disabled={true} className={'holder-' + variant}>
                    <option value=''>-Select an Aircraft-</option>
                </Form.Select>
            </Form.Group>
        }

        if (!craftdata.activeAgreements || craftdata.activeAgreements.length === 0) {
            return <>
                <MissingContract />
                <Form.Group className="mb-3">
                    <Form.Label>Aircraft</Form.Label>
                    <Form.Select disabled={true} className={variant}>
                        <option value=''>-Select an Aircraft-</option>
                    </Form.Select>
                </Form.Group>
            </>
        }

        return <Form.Group className="mb-3">
            <Form.Label>Aircraft</Form.Label>
            <Form.Select id="aircraft" value={state.tailNumber} onChange={aircraftChange}>
                <option value=''>-Select an Aircraft-</option>
                {craftdata.activeAgreements.map((aircraft) => (
                    <option key={aircraft.tailNumber} value={aircraft.tailNumber}>{aircraft.tailNumber}</option>
                ))}
            </Form.Select>
        </Form.Group>
    }

    const isValid = () => {
        return state.tailNumber != '' && state.catagory != '' && state.request != '';
    }

    // if (loadAircraft || loadCreate) return <p>Loading ...</p>;

    if (state.completed) {
        return <div id="message">
            Your maintenace request has been recorded.
        </div>
    }

    return (
        <Container>
            <Row>
                <Col lg={6}>
                    <AircraftSelection />
                </Col>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Catagory</Form.Label>
                        <Form.Select id="catagory" value={state.catagory} onChange={handlecatagoryChange}>
                            <option value=''>-Select a Catagory-</option>
                            <option value='Avionics'>Avionics</option>
                            <option value='Control'>Control Surfaces</option>
                            <option value='Exterior'>Exterior</option>
                            <option value='Interior'>Interior</option>
                            <option value='Power'>Power Plant</option>
                            <option value='Other'>Other</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col lg={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Describe the Issue</Form.Label>
                        <Form.Control as="textarea" rows={3} name="request"
                            onChange={handleDescriptionChange}
                            value={state.request} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: '10px' }}>
                    <Button id="save" variant={variant} disabled={isValid() == false} onClick={saveRequest}>Save</Button>
                </Col>
            </Row>
        </Container>

    )
}

export default Maintenance
import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import { Col, Container, Row, Button } from 'react-bootstrap';

import { useQuery } from '@apollo/client';
import { GET_AIRCRAFT_W_MAINT } from '../../gql/Aircraft';
import { roles } from '../../reducers/auth';
import MaintenanceList from '../../components/MaintenanceList';


const Aircraft = ({ variant }) => {
    const userRoles = useSelector(roles);
    const { id } = useParams();

    const { loading, data } = useQuery(GET_AIRCRAFT_W_MAINT, {
        variables: {
            tailNumber: id
        }
    });

    if (userRoles.includes('admin') === false) return <>Unauthorized</>

    if (loading) return <Container>
        Loading...
    </Container>

    return (
        <Container>
            <Row>
                <Col>
                    Tail Number
                </Col>
                <Col>
                    {data.aircraft.tailNumber}
                </Col>
            </Row>
            <Row>
                <Col>
                    Type
                </Col>
                <Col>
                    {data.aircraft.type}
                </Col>
            </Row>
            <Row>
                <Col>
                    Status
                </Col>
                <Col>
                    {data.aircraft.status}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button name="schedMaint" variant={variant} href={`/admin/aircraft/${id}/schedulemaint`}>Schedule Maintenance</Button><br />
                    <MaintenanceList variant={variant} requests={data.aircraft.openMaintenance} withLink={true} />
                </Col>
            </Row>
        </Container>
    )
}

export default Aircraft
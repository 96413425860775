import React from 'react';
import { useSelector } from 'react-redux'

import './App.css';

import NavBar from './components/NavBar';
import Dashboard from './pages/Dashboard/Dashboard';
import NewReservation from './pages/NewReservation';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ApolloProvider } from "@apollo/client";
import gqlClient from './Apollo';

import Flights from './pages/Flights/Flights';
import ErrorPage from './pages/Error';
import { Login } from './pages/Login';
import Checkout from './pages/Flights/Checkout';
import FlightDetail from './pages/Flights/FlightDetail';

import { isAuthinticated } from './reducers/auth';
import Maintenance from './pages/Maintenance';
import AircraftList from './pages/admin/AircraftList';
import Aircraft from './pages/admin/Aircraft';
import SchedMaint from './pages/admin/SchedMaint';
import AddCard from './pages/Billing/AddCard';
import ReturnCard from './pages/Billing/ReturnCard';
import Billing from './pages/Billing/Billing';

import ViewMaintAdmin from './pages/admin/ViewMaintAdmin';
import Prepaid from './pages/Billing/Prepaid';
import Footer from './components/Footer';

function App() {
  const authinticated = useSelector(isAuthinticated);

  // const authinticated = useSelector((state) => state.authintication.value)

  const variant = 'lighter';
  // const variant = 'dark';

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <><NavBar variant={variant} /><Dashboard variant={variant} /></>,
        errorElement: <><NavBar variant={variant} /><ErrorPage /></>
      },
      {
        path: "/flights",
        element: <><NavBar variant={variant} /><Flights variant={variant} /></>
      },
      {
        path: "/reservation/:id",
        element: <><NavBar variant={variant} /><NewReservation variant={variant} /></>
      },
      {
        path: "/checkout/:id",
        element: <><NavBar variant={variant} /><Checkout variant={variant} /></>
      },
      {
        path: "/flight/:id",
        element: <><NavBar variant={variant} /><FlightDetail variant={variant} /></>
      },
      {
        path: "/maintenance",
        element: <><NavBar variant={variant} /><Maintenance variant={variant} /></>
      },
      {
        path: "/billing",
        element: <><NavBar variant={variant} /><Billing variant={variant} /></>
      },
      {
        path: "/billing/addcard",
        element: <><NavBar variant={variant} /><AddCard variant={variant} /></>
      },
      {
        path: "/billing/thankyou?*",
        element: <><NavBar variant={variant} /><ReturnCard variant={variant} /></>
      },
      {
        path: "/prepay",
        element: <><NavBar variant={variant} /><Prepaid variant={variant} /></>
      },
      {
        path: "/admin",
        element: <><NavBar variant={variant} /><AircraftList variant={variant} /></>
      },
      {
        path: "/admin/aircraft/:id",
        element: <><NavBar variant={variant} /><Aircraft variant={variant} /></>
      },
      {
        path: "/admin/aircraft/:id/schedulemaint",
        element: <><NavBar variant={variant} /><SchedMaint variant={variant} /></>
      },
      {
        path: "/admin/maintenance/:id",
        element: <><NavBar variant={variant} /><ViewMaintAdmin variant={variant} /></>
      },
    ]
  );


  // if (Date.now() >= exp * 1000) {
  //   return false;
  // }

  // if (!authinticated) {
  //   localStorage.clear();
  //   return <Login />
  // }

  return (
    <React.StrictMode>
      <ApolloProvider client={gqlClient}>
        {!authinticated && <><Login /> <Footer /></>}
        {authinticated &&
          <div className={'App ' + variant}>
            {/* <NavBar variant={variant} /> */}

            <RouterProvider router={router} fallbackElement={<>Loading...</>} />
          </div>
        }
      </ApolloProvider>
    </React.StrictMode >)
}

export default App;

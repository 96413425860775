import React from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { useQuery, gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { flags } from '../../reducers/auth';

const GET_FLIGHTS = gql`
    query MyFlights {
        myFlights {
            id
            start
            aircraft {
                tailNumber
                type
            }
            hours
            end
            invoice {
                id
                invoicedAmount
                invoicedHours
                prePaidAmount
                prePaidHours
                totalAmount
            }
        }
    }
`;

const Flights = ({ variant = 'lighter' }) => {
    const { loading, error, data: flightData } = useQuery(GET_FLIGHTS);
    const userFlags = useSelector(flags);

    if (loading) {
        return <Container>
            <Row>
                <Col>
                    <Card style={{ width: '100%' }}
                        bg={variant.toLowerCase()}
                        text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
                        className="mb-2">
                        <Card.Body>
                            <Card.Title>Past Flights</Card.Title>
                            <Card.Text>
                                <Table striped bordered variant={variant.toLowerCase()} responsive="lg">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Aircraft</th>
                                            <th>Hours</th>
                                            {userFlags.includes('prepay') && <th>Prepaid</th>}
                                            <th>Billed to Card</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className={'holder-' + variant}>&nbsp;</div>
                                            </td>
                                            <td>
                                                <div className={'holder-' + variant}>&nbsp;</div><br />
                                                <div className={'holder-' + variant}>&nbsp;</div>
                                            </td>
                                            <td>
                                                <div className={'holder-' + variant}>&nbsp;</div>
                                            </td>
                                            <td>
                                                <div className={'holder-' + variant}>&nbsp;</div>
                                            </td>
                                            <td>
                                                <div className={'holder-' + variant}>&nbsp;</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }

    if (error) return `Error! ${error}`;

    const { myFlights } = flightData

    return (
        <Container>
            <Row>
                <Col>
                    <div className="pageTitle">Past Flights</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered variant={variant.toLowerCase()} responsive="lg">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Aircraft</th>
                                <th>Hours</th>
                                {userFlags.includes('prepay') && <th>Prepaid</th>}
                                <th>Billed to Card</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myFlights.map((flight) => (
                                <tr>
                                    <td>
                                        {DateTime.fromSeconds(flight.start).toLocaleString(DateTime.DATE_SHORT)}
                                    </td>
                                    <td>
                                        {flight.aircraft.tailNumber}<br />
                                        {flight.aircraft.type}
                                    </td>
                                    <td>
                                        {flight.hours}
                                    </td>
                                    {userFlags.includes('prepay') &&
                                        <td>
                                            ${flight.invoice.prePaidAmount.toFixed(2)}
                                        </td>
                                    }
                                    <td>
                                        ${flight.invoice.invoicedAmount.toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

Flights.propTypes = {}

export default Flights
import React from 'react'
import { Table } from 'react-bootstrap';
import { DateTime } from 'luxon';

const MaintenanceList = ({ variant, requests, withLink = false }) => {
    if (withLink) {
        return (
            <Table striped bordered variant={variant.toLowerCase()}>
                <thead>
                    <th>Catagory</th>
                    <th>Item</th>
                    <th>Due By</th>
                </thead>
                <tbody>
                    {requests.map((maintenance) => (
                        <tr key={maintenance.id}>
                            <td><a href={`/admin/maintenance/${maintenance.id}`} className={variant}>{maintenance.catagory}</a></td>
                            <td>{maintenance.request}</td>
                            <td>
                                {maintenance.dueDate && <>{DateTime.fromSeconds(maintenance.dueDate).toLocaleString(DateTime.DATE_SHORT)}&nbsp;</>}
                                {maintenance.dueAtHobbs && <>Hobbs&#64;{maintenance.dueAtHobbs}</>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    return (

        <Table striped bordered variant={variant.toLowerCase()}>
            <thead>
                <th>Catagory</th>
                <th>Item</th>
                <th>Due By</th>
            </thead>
            {requests.map((maintenance) => (
                <tr key={maintenance.id}>
                    <td>{maintenance.catagory}</td>
                    <td>{maintenance.request}</td>
                    <td>
                        {maintenance.dueDate && <>{DateTime.fromSeconds(maintenance.dueDate).toLocaleString(DateTime.DATE_SHORT)}&nbsp;</>}
                        {maintenance.dueAtHobbs && <>Hobbs&#64;{maintenance.dueAtHobbs}</>}
                    </td>
                </tr>
            ))}
        </Table>
    )
}

export default MaintenanceList
import React, { useState } from 'react'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import { gql } from '@apollo/client';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { flags } from '../../reducers/auth';

export const GET_AVAILABLE_AIRCRAFT = gql`
    query {
        activeAgreements{
            tailNumber
            hourlyRate
        }
        paymentMethods {
            id
            last4
            brand
            default
        }
    }
`;

export const PURCHASE_HOURS = gql`
    mutation Prepaid($input: PrepaidInput!) {
        prepay(input: $input){
            id
            credit
        }
    }
`;


const Prepaid = ({ variant }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const userFlags = useSelector(flags);


    const { loading, data } = useQuery(GET_AVAILABLE_AIRCRAFT);
    const [completePurchase, { loading: loadPurchase }] = useMutation(PURCHASE_HOURS);

    const [state, setState] = useState({
        tailNumber: '',
        hours: 10,
        rate: 0,
        isValid: false,
        card: undefined,
        complete: false,
    })

    if (userFlags.includes('prepay') === false) { return <></> }

    const aircraftChange = (event) => {
        const tailNumber = event.target.value;
        const agreement = data.activeAgreements.find(e => e.tailNumber === tailNumber);

        const isValid = checkValid({
            ...state,
            tailNumber
        });

        setState({
            ...state,
            tailNumber,
            rate: agreement ? agreement.hourlyRate : 0,
            isValid
        })
    }

    const hoursChange = (event) => {
        const hours = parseFloat(event.target.value);

        const isValid = checkValid({
            ...state,
            hours
        });

        setState({
            ...state,
            hours,
            isValid
        })
    }
    const cardChange = (event) => {
        const card = event.target.value;

        if (card === 'addcard') {
            return navigate(`/billing/addcard?ret=${btoa(location.pathname + location.search)}`);
        }

        const isValid = checkValid({
            ...state,
            card
        });

        setState({
            ...state,
            card,
            isValid
        })
    }

    const handlePurchase = () => {
        completePurchase({
            variables: {
                input: {
                    hours: state.hours,
                    tailNumber: state.tailNumber,
                    cardId: state.card,
                }
            },
            onCompleted: () => {
                if (searchParams.get('return')) {
                    return navigate(searchParams.get('return'));
                }
                setState({
                    ...state,
                    complete: true,
                })
            },
            onError: (e) => {
                console.error(e);
            },
        });
    }

    const checkValid = (newState) => {
        return newState.hours > 0 && newState.tailNumber !== '' && newState.card !== undefined;
    }

    if (loading || loadPurchase) return <>
        Loading...
    </>;

    if (state.complete) {
        return <Container>
            Your hours have been added to your account.<br />
            Enjoy your flying.
        </Container>
    }

    return (
        <Container>
            <div>Purchase hours up front and Save.</div>
            <div>Hours can be pre-purchased in blocks of 10.</div>
            <Row>
                <Col lg={6}>
                    <Form.Label>Aircraft</Form.Label>
                    <Form.Select name="aircraft" value={state.tailNumber} onChange={aircraftChange}>
                        <option value=''>-Select an Aircraft-</option>
                        {data.activeAgreements.map((agreement) => (
                            <option key={agreement.tailNumber} value={agreement.tailNumber}>{agreement.tailNumber} </option>
                        ))}
                    </Form.Select>
                </Col>

                <Col lg={6}>
                    <Form.Label>Hours</Form.Label>
                    <Form.Select name="hours" value={state.hours} onChange={hoursChange}>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='30'>30</option>
                        <option value='40'>40</option>
                        <option value='50'>50</option>
                    </Form.Select>
                </Col>
            </Row>
            {state.rate !== 0 &&
                <>
                    <Row>
                        <Col xs={5}>
                            <b>Normal Price:</b>
                        </Col>
                        <Col xs={7}>
                            ${state.rate} per hour
                        </Col>
                        <Col xs={5}>
                            <b>Prepaid Price:</b>
                        </Col>
                        <Col xs={7}>
                            ${state.rate - 5}  per hour
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={12}>
                            There are no refunds on unused hours. Prepaid hours are not transferable.<br />
                            <b>Total:</b> ${state.hours * (state.rate - 5)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Label>Payment Method</Form.Label>
                            <Form.Select name="card" value={state.card} onChange={cardChange}>
                                <option value=''>-Select a Card-</option>
                                <option value='addcard'>Add new Card</option>
                                {data.paymentMethods.map((card) => (
                                    <option key={card.id} value={card.id}>{card.brand} {card.last4}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={6}><br />
                            <Button href="/billing/addcard">Add New Card</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button variant={variant} disabled={state.isValid === false} onClick={handlePurchase}>Complete Purchase</Button>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    )
}

export default Prepaid
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery, gql } from '@apollo/client';
import { Button, Col, Container, Row } from 'react-bootstrap';
import MissingContract from '../../components/MissingContract';

const stripePromise = loadStripe('pk_live_51PXrp1BrozHyNNdQYnkvJl7XVu9uBkntwAlvhA3pwZFW9BYj3L2b0M0rAOnse8O6vDMaY5qWmle9Nl40iTDi7xrD00CVCfVacz');

const GET_TOKEN = gql`
    query Query {
        StartPaymentIntent
        activeAgreements{
            tailNumber
        }
    }
`;

const CardForm = ({ variant = 'lighter' }) => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState()
    const [agreeChecked, setAgreeChecked] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    //
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (elements == null) {
            return;
        }
        setIsLoading(true);

        let return_url = 'https://clearedskies.com/billing/thankyou?ret=' + (searchParams.get('ret') ? searchParams.get('ret') : '/billing');

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url
            }
        });
        if (error) {
            setIsLoading(false);
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <Container>
            <Row>
                <Col lg={6}>
                    <form onSubmit={handleSubmit}>
                        {errorMessage}
                        <PaymentElement />
                        <label>
                            <input
                                type="checkbox"
                                checked={agreeChecked}
                                onChange={(event) => setAgreeChecked(event.currentTarget.checked)}
                            /> I agree that Cleared Skies LLC may charge this card for future flights AND oustanding invoices.
                        </label>
                        <br />
                        <Button type="submit" variant={variant} disabled={!stripe || !elements || isLoading || !agreeChecked}>Save Card</Button>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}


const AddCard = () => {
    const { loading, data } = useQuery(GET_TOKEN);
    if (loading) return <>
        Loading...
    </>

    if (!data.activeAgreements) {
        return <MissingContract />
    }

    const options = {
        // passing the client secret obtained from the server
        clientSecret: data.StartPaymentIntent,
    };

    return (
        <Elements stripe={stripePromise} options={options} >
            <CardForm />
        </Elements>
    )
}

export default AddCard
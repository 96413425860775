import React from 'react'
import { Button, Card, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import { useQuery, useMutation } from '@apollo/client';
import { CREATE, GET_RESERVATIONS_W_INFLIGHT } from "../../../gql/Reservations";

import { DateTime } from 'luxon';

const Reservations = ({ variant = 'light' }) => {
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(GET_RESERVATIONS_W_INFLIGHT, {
        fetchPolicy: 'network-only'
    });

    const [createReservation, { loading: loadCreate }] = useMutation(CREATE);

    const handleNewReservation = (event) => {
        event.preventDefault();
        createReservation({
            onCompleted({ ReservationCreate }) {
                return navigate(`/reservation/${ReservationCreate.id}`);
            }
        })
    }

    const viewFlight = (event, flightId) => {
        event.preventDefault();
        return navigate(`/flight/${flightId}`);
    }

    if (loadCreate) return <>
        Loading...
    </>

    if (loading) return <Card style={{ width: '100%' }}
        bg={variant.toLowerCase()}
        text={variant.toLowerCase()}
        className="mb-2">
        <Card.Body>
            <Card.Title>Upcoming Flights
                <div style={{ float: 'right', paddingBottom: '5px' }}><Button name="newReservation" variant={variant} href="/reservation" disabled={true}>New Reservation</Button></div>
            </Card.Title>
            <Card.Text>
                <Table striped bordered variant={variant.toLowerCase()}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Aircraft</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                            <td>
                                <div className={'holder-' + variant}>&nbsp;</div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Text>
        </Card.Body>
    </Card>;


    if (error) return <p>Error : {error.message}</p>;

    return (
        <>
            {data.inFlight.length > 0 &&
                <Card style={{ width: '100%' }}
                    bg={variant.toLowerCase()}
                    text={variant.toLowerCase() === 'lighter' ? 'black' : 'dark'}
                    className="mb-2">
                    <Card.Body>
                        <Card.Title>
                            Your active flight.
                        </Card.Title>
                        <Card.Text>
                            {data.inFlight.map((flight) => (
                                <span key={flight.id}>
                                    Aircraft: &nbsp;
                                    < a className='flightDate' href={`flight/${flight.id}`} onClick={(event) => viewFlight(event, flight.id)} style={{ textDecoration: 'none' }}>
                                        {flight.aircraft.tailNumber} - {flight.aircraft.type}
                                    </a>
                                </span>
                            ))}
                        </Card.Text>
                    </Card.Body>
                </Card>}

            <Card style={{ width: '100%' }}
                bg={variant.toLowerCase()}
                text={variant.toLowerCase()}
                className="mb-2">
                <Card.Body>
                    <Card.Title>Upcoming Flights
                        <div style={{ float: 'right', paddingBottom: '5px' }}>
                            <Button name="newReservation" variant={variant} href="/reservation" onClick={handleNewReservation} disabled={!data.activeAgreements}>New Reservation</Button>
                        </div>
                    </Card.Title>
                    <Card.Text>
                        <Table striped bordered variant={variant.toLowerCase()}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Aircraft</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.myReservations.map((flight) => (
                                    <tr key={flight.id}>
                                        <td>
                                            <a className={variant} href={`flight/${flight.id}`} onClick={(event) => viewFlight(event, flight.id)} style={{ textDecoration: 'none' }}>
                                                {DateTime.fromSeconds(flight.start).toLocaleString(DateTime.DATE_SHORT)}
                                            </a>
                                        </td>
                                        <td>
                                            <a className={variant} href={`flight/${flight.id}`} onClick={(event) => viewFlight(event, flight.id)} style={{ textDecoration: 'none' }}>
                                                {DateTime.fromSeconds(flight.start).toLocaleString(DateTime.TIME_24_SIMPLE)}
                                            </a>
                                        </td>
                                        <td>
                                            <a className={variant} href={`flight/${flight.id}`} onClick={(event) => viewFlight(event, flight.id)} style={{ textDecoration: 'none' }}>
                                                {DateTime.fromSeconds(flight.end).toLocaleString(DateTime.TIME_24_SIMPLE)}
                                            </a>
                                        </td>
                                        <td>
                                            <a className={variant} href={`flight/${flight.id}`} onClick={(event) => viewFlight(event, flight.id)} style={{ textDecoration: 'none' }}>
                                                {flight.aircraft.tailNumber}
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

Reservations.propTypes = {}

export default Reservations
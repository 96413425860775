import React, { useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import { setLogout } from '../reducers/auth';
import { roles } from '../reducers/auth';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import jwt from 'jsonwebtoken';
import { useMutation } from '@apollo/client';
import { CREATE } from '../gql/Reservations';
import { L8N } from '../l8n';

const NavBar = ({ variant = 'light' }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();


    const [createReservation, { loading: loadCreate }] = useMutation(CREATE);

    const authinticated = useSelector(state => state.authintication.value);
    const userRoles = useSelector(roles);

    useEffect(() => {
        if (!authinticated || jwt.decode(authinticated).exp * 1000 <= Date.now()) {
            dispatch(setLogout());
            return;
        }
    }, [location])

    const logout = (event) => {
        dispatch(setLogout());
    }

    const handleNewReservation = (event) => {
        event.preventDefault();
        createReservation({
            onCompleted({ ReservationCreate }) {
                if (!ReservationCreate) return navigate(`/`);

                return navigate(`/reservation/${ReservationCreate.id}`);
            }
        })
    }

    return (
        <Navbar
            bg={variant.toLowerCase()}
            variant={variant.toLowerCase()}
            expand="lg">
            <Container>
                <Navbar.Brand id="dashboard-nav" href="/" >{L8N.siteTitle}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/" >Dashboard</Nav.Link>
                        <Nav.Link href="/reservation" onClick={handleNewReservation} >New Reservation</Nav.Link>
                        <Nav.Link href="/flights" >Past Flights</Nav.Link>
                        <Nav.Link href="/maintenance" >Maintenance Request</Nav.Link>
                        <Nav.Link href="/Billing" >Billing</Nav.Link>
                        {userRoles.includes('admin') && <Nav.Link href="/admin" >Admin</Nav.Link>}
                        <Nav.Link href="/" onClick={logout}>Log Out</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar
import React, { useState } from 'react'
import { Navigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { useQuery, gql, useMutation } from '@apollo/client';
import { CANCEL } from '../../gql/Reservations';

import FlightDetails from '../../components/FlightDetails';
import OpenMaintenance from '../../components/OpenMaintenance';
import { CompletedFlight } from './CompletedFlight';
import { DateTime } from "luxon";


const GET_RESERVATION = gql`
    query Reservation($reservationId: String!) {
        reservation(id: $reservationId) {
            id
            start
            end
            stage
            aircraft {
                tailNumber
                type
                openMaintenance {
                    id
                    status
                    request
                    catagory
                    dueAtHobbs
                    dueDate
                }
            }
            flight {
                id
                start
                hours
                end
                aircraft {
                    tailNumber
                    type
                }
            }
        }
        inFlight {
            id
        }
    }
`;


const FlightDetail = ({ variant = 'lighter' }) => {
    const [startCheckout, setStartCheckout] = useState(false);
    const [showCancel, setShowCancel] = useState(false)

    let { id } = useParams();

    const { loading, error, data, refetch } = useQuery(GET_RESERVATION, {
        variables: {
            reservationId: id
        }
    });

    const [cancelReservation, { loading: loadCancel }] = useMutation(CANCEL);


    if (loading) {
        return <Container>
            <div className="pageTitle">Flight Details</div>
            <FlightDetails loading={loading} variant={variant}></FlightDetails>

            <Col xs={6} style={{ marginTop: '10px' }}>
                <Button name="start" disabled={true} variant={variant}>Start Checkout</Button>
            </Col>
            <Col xs={6} style={{ marginTop: '10px' }}>
                <Button name="cancel" disabled={true} variant={variant}>Cancel</Button>
            </Col>
        </Container>
    }

    if (error) return <p>Error : {error.message}</p>;

    const handleStart = (event) => {
        event.preventDefault();
        setStartCheckout(true);
    }

    const handleShowCancel = (event) => {
        event.preventDefault();
        setShowCancel(true);
    }

    const handleCancel = () => {
        cancelReservation({
            variables: {
                reservationCancelId: id
            },
            onCompleted: ({ canceled }) => {
                refetch().then(setShowCancel(false));
            }
        })
    }

    const handleClose = () => {
        setShowCancel(false);
    }

    if (startCheckout || data.reservation.stage === 'INFLIGHT') {
        return <Navigate to={`/checkout/${id}`} replace={true} />
    }

    const ReservedFlight = () => {
        return <>
            <FlightDetails loading={loading} flightData={data.reservation} variant={variant}></FlightDetails>
            <Row>
                <Col xs={12} style={{ marginTop: '10px' }}>
                    <OpenMaintenance reservationId={id} variant={variant} requests={data.reservation.aircraft.openMaintenance} />
                    {data.inFlight.length > 0 &&
                        <p className={'warning-' + variant}>
                            You currently have an aircraft checked out. Please finish your current flight before starting another.
                        </p>
                    }
                </Col>
            </Row>
            <Row>
                {DateTime.fromSeconds(data.reservation.start).diffNow('minute').minutes > 30 &&
                    <Col xs={12} style={{ marginTop: '10px' }}>
                        <strong>Airplane checkout can be started after {DateTime.fromSeconds(data.reservation.start).minus({ minutes: 30 }).toLocaleString(DateTime.TIME_24_SIMPLE)} on {DateTime.fromSeconds(data.reservation.start).minus({ minutes: 30 }).toLocaleString(DateTime.DATE_HUGE)}.</strong>
                    </Col>
                }

                <Col xs={6} style={{ marginTop: '10px' }}>
                    <Button name="cancel" disabled={data.inFlight.length > 0} variant='danger' onClick={handleShowCancel}>Cancel Reservation</Button>
                </Col>
                <Col xs={6} style={{ marginTop: '10px' }}>
                    {DateTime.fromSeconds(data.reservation.start).diffNow('minute').minutes <= 30 && <Button name="start" disabled={data.inFlight.length > 0} variant={variant} href={`/checkout/${id}`} onClick={handleStart}>Start Checkout</Button>}
                </Col>
            </Row>
        </>
    }

    const CanceledFlight = () => {
        return <>
            <FlightDetails loading={loading} flightData={data.reservation} variant={variant}></FlightDetails>

            <Col style={{ marginTop: '10px' }}>
                <p className={'warning-' + variant}>
                    This reservation has been canceled.
                </p>
            </Col>
        </>
    }

    return (
        <Container>
            <Modal show={showCancel} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Flight</Modal.Title>
                </Modal.Header>
                <Modal.Body>You are about to cancel this reservation.<br />Are you sure you want to do this?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={loadCancel}>
                        Go Back
                    </Button>
                    <Button variant="danger" onClick={handleCancel} disabled={loadCancel}>
                        Cancel Reservation
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="pageTitle">Flight Details</div>
            {data.reservation.stage === 'RESERVED' && <ReservedFlight />}
            {data.reservation.stage === 'CANCELED' && <CanceledFlight />}

            {data.reservation.stage === 'COMPLETED' && <CompletedFlight flight={data.reservation.flight} />}
        </Container>
    )
}

FlightDetail.propTypes = {}

export default FlightDetail